<template>
  <div>
    <v-container fluid>

      <div class="main">
        <v-row justify="center">
          <v-col cols="12" lg="9">

            <v-card-title class="pl-0 pr-0">

              <v-icon v-if="!id" color="primary" left>mdi-plus-circle</v-icon>
              <v-icon v-if="id" color="primary" left>mdi-pencil-outline</v-icon>
              <span v-if="!id">Ajouter une cashback</span>
              <span v-if="id"> Modifier une cashback</span>

              <v-spacer/>

              <v-btn color="primary" dark depressed fab small to="/cashback">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-card-title>

            <v-card :disabled="btnLoading || assignmentBtnLoading || searchLoading || showLoading"
                    class="rounded-lg shadow">

              <v-overlay :value="searchLoading || showLoading" absolute color="white" opacity="0.5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-text>
                <v-tabs v-model="tab"
                        active-class="gifty white--text"
                        centered
                        hide-slider>

                  <v-tab :disabled="tab === 1" class="rounded-lg">
                    <v-icon left>mdi-cog</v-icon>
                    Configuration
                  </v-tab>

                  <v-tab :disabled="tab === 0" class="rounded-lg">
                    <v-icon left>mdi-account-multiple-check</v-icon>
                    Affectation
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">

                  <v-tab-item>
                    <v-card flat>
                      <v-card-text class="pa-8">

                        <v-text-field v-model.trim="form.name"
                                      :error-messages="errors.name"
                                      label="Nom de cashback *"/>

                        <v-row>

                          <v-col>
                            <v-menu
                                v-model="menuStartDate"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                min-width="auto"
                                offset-y
                                transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="form.start_date"
                                              :error-messages="errors.start_date"
                                              append-icon="mdi-calendar"
                                              label="Date de début *"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="form.start_date"
                                             :min="dateNow"
                                             @input="[menuStartDate = false,form.end_date = null]"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col>
                            <v-menu
                                v-model="menuEndDate"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                min-width="auto"
                                offset-y
                                transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="form.end_date"
                                              :disabled="!form.start_date"
                                              :error-messages="errors.end_date"
                                              append-icon="mdi-calendar"
                                              label="Date de fin *"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="form.end_date"
                                             :min="form.start_date"
                                             @input="menuEndDate = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                        </v-row>

                        <div class="font-weight-medium py-4 text-center">Configuration des Services *</div>

                        <v-card class="rounded-lg" outlined>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th width="20%">Service</th>
                                <th width="20%">Montant min</th>
                                <th width="20%">Montant max</th>
                                <th width="20%">Type</th>
                                <th width="20%">Valeur</th>
                                <th></th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(service,i) in form.services" :key="i">
                                <td>

                                  <v-autocomplete v-model="service.service_id"
                                                  :disabled="serviceLoading"
                                                  :error-messages="errors['services.' + i + '.service_id']"
                                                  :items="services"
                                                  :loading="serviceLoading"
                                                  item-text="name"
                                                  item-value="id"
                                                  @input="errors['services.' + i + '.service_id'] = null"
                                  >


                                    <template v-slot:item="data">
                                      <template v-if="typeof data.item !== 'object'">
                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                      </template>
                                      <template v-else>
                                        <v-list-item-avatar>
                                          <v-img :src="getImageLink(data.item.image)" contain></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title
                                              v-html="data.item.name"></v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </template>

                                  </v-autocomplete>
                                </td>

                                <td>
                                  <v-text-field v-model="service.min_amount"
                                                :error-messages="errors['services.' + i + '.min_amount']"
                                                clearable
                                                suffix="DZD"
                                                @input="errors['services.' + i + '.min_amount'] = null"
                                  />
                                </td>

                                <td>
                                  <v-text-field v-model="service.max_amount"
                                                :error-messages="errors['services.' + i + '.max_amount']"
                                                clearable
                                                suffix="DZD"
                                                @input="errors['services.' + i + '.max_amount'] = null"
                                  />
                                </td>

                                <td>
                                  <v-select v-model="service.type"
                                            :error-messages="errors['services.' + i + '.type']"
                                            item-text="name"
                                            item-value="value"
                                            :items="[
                                                {
                                                   name : 'Pourcentage' , value : 'percentage',
                                                },
                                                {
                                                   name : 'Montant' , value : 'amount',
                                                },
                                            ]"
                                            @input="errors['services.' + i + '.type'] = null"></v-select>
                                </td>

                                <td>
                                  <v-text-field v-model="service.value"
                                                :error-messages="errors['services.' + i + '.value']"
                                                clearable
                                                :suffix="service.type === 'percentage' ? '%' : 'DZD'"
                                                @input="errors['services.' + i + '.value'] = null"
                                  />
                                </td>

                                <td>
                                  <v-icon v-if="form.services.length>1"
                                          color="red"
                                          @click="form.services.splice(i,1)">mdi-close-circle
                                  </v-icon>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="5">
                                  <v-btn class="rounded" color="primary" depressed fab
                                         x-small @click="form.services.push({type: 'percentage'})">
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card>


                        <label class="d-block mt-6">
                          Méthodes de conversion *
                        </label>

                        <div class="d-flex">

                          <v-checkbox hide-details
                                      v-model="form.conversion_methods"
                                      value="sms"
                                      multiple
                                      label="SMS"></v-checkbox>

                          <v-checkbox hide-details
                                      class="ml-5"
                                      v-model="form.conversion_methods"
                                      value="account"
                                      multiple
                                      label="Account"></v-checkbox>

                          <v-checkbox hide-details
                                      class="ml-5"
                                      v-model="form.conversion_methods"
                                      value="gifty"
                                      multiple
                                      label="Gifty"></v-checkbox>

                          <v-checkbox v-model="form.conversion_methods"
                                      value="app"
                                      class="ml-5"
                                      multiple
                                      hide-details
                                      label="APP"></v-checkbox>
                        </div>

                        <small class="red--text" v-if="errors && errors.conversion_methods">
                          {{ errors.conversion_methods.toString() }}
                        </small>


                        <label class="d-block mt-6">
                          Méthodes de paiement *
                        </label>

                        <div class="d-flex">
                          <v-checkbox hide-details
                                      v-model="form.payment_methods"
                                      value="gifty"
                                      multiple
                                      label="Gifty"></v-checkbox>

                          <v-checkbox v-model="form.payment_methods"
                                      value="cib"
                                      hide-details
                                      class="ml-5"
                                      multiple
                                      label="CIB"></v-checkbox>
                        </div>
                        <small class="red--text" v-if="errors && errors.payment_methods">
                          {{ errors.payment_methods.toString() }}
                        </small>

                        <v-divider class="my-5"/>

                        <label class="d-block">
                          Type d'affectation *
                        </label>

                        <v-chip-group v-model="form.assignment_type"
                                      center-active
                                      mandatory>

                          <v-chip active-class="gifty white--text"
                                  filter
                                  label
                                  value="all"
                          >
                            Tout le monde
                            <v-icon dense right>mdi-earth</v-icon>
                          </v-chip>

                          <v-chip active-class="gifty white--text"
                                  filter
                                  label
                                  value="custom">
                            Utilisateurs spécifiques
                            <v-icon dense right>mdi-format-list-checks</v-icon>
                          </v-chip>

                        </v-chip-group>

                        <v-checkbox v-if="form.assignment_type === 'all'"
                                    v-model="form.users_assignment_type"
                                    class="mt-2"
                                    false-value="specify"
                                    hide-details
                                    label="Exclure des utilisateurs ?"
                                    true-value="exclude"/>

                        <v-switch v-model="form.is_active" color="success" dense
                                  label="Active/Inactive"></v-switch>

                      </v-card-text>

                      <v-card-actions>

                        <v-spacer/>

                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :loading="btnLoading"
                                   color="primary"
                                   text
                                   v-bind="attrs"
                                   v-on="on"
                            >
                              <v-icon left>mdi-content-save</v-icon>
                              Enregistrer
                            </v-btn>
                          </template>
                          <v-list>

                            <v-list-item @click="save('new')">
                              <v-list-item-icon>
                                <v-icon color="success">mdi-check-circle</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Enregistrer et nouveau
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="save('back')">
                              <v-list-item-icon>
                                <v-icon color="success">mdi-check-circle</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Enregistrer et retour
                              </v-list-item-title>
                            </v-list-item>

                          </v-list>
                        </v-menu>

                        <v-btn :disabled="checkDisabled()"
                               :loading="assignmentBtnLoading"
                               color="primary"
                               depressed
                               @click="save('befor_assignment')">
                          Affectation
                          <v-icon right>mdi-arrow-right</v-icon>
                        </v-btn>

                      </v-card-actions>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card flat>


                      <v-card-text v-if="onFilter" class="pa-8">

                        <v-alert border="left" text type="info">
                          Utilisez ce formulaire ci-dessous pour filtrer les utilisateurs.
                          <span class="d-block"><strong>Note : </strong>Les paramètres de filtre sont automatiquement enregistrés.</span>
                        </v-alert>

                        <v-text-field
                            v-model.trim="filter.keyword"
                            clearable
                            label="N°Téléphone"
                            prepend-icon="mdi-magnify"
                        />

                        <v-select v-model="filter.database_ids"
                                  :items="databases"
                                  chips
                                  deletable-chips
                                  item-text="name"
                                  item-value="id"
                                  label="Base de données"
                                  multiple
                                  prepend-icon="mdi-filter-outline"
                                  small-chips
                        />

                        <v-autocomplete v-model="filter.country_ids"
                                        :items="countries"
                                        chips
                                        deletable-chips
                                        item-text="name"
                                        item-value="id"
                                        label="Pays"
                                        multiple
                                        prepend-icon="mdi-filter-outline"
                                        small-chips
                        />

                        <v-autocomplete v-model="filter.wilaya_ids"
                                        :items="wilayas"
                                        chips
                                        deletable-chips
                                        item-text="name"
                                        item-value="id"
                                        label="Wilayas"
                                        multiple
                                        prepend-icon="mdi-filter-outline"
                                        small-chips
                        />

                        <v-autocomplete v-model="filter.category_ids"
                                        :items="categories"
                                        chips
                                        deletable-chips
                                        item-text="name"
                                        item-value="id"
                                        label="Catégories"
                                        multiple
                                        prepend-icon="mdi-filter-outline"
                                        small-chips
                        />

                        <v-autocomplete v-model="filter.versions"
                                        :items="versions"
                                        chips
                                        deletable-chips
                                        item-text="name"
                                        item-value="id"
                                        label="Versions"
                                        multiple
                                        prepend-icon="mdi-filter-outline"
                                        small-chips
                        />

                        <v-autocomplete v-model="filter.role_ids"
                                        :items="roles"
                                        chips
                                        deletable-chips
                                        item-text="name"
                                        item-value="id"
                                        label="Rôles"
                                        multiple
                                        prepend-icon="mdi-filter-outline"
                                        small-chips
                        />

                        <v-alert v-if="searchResult === 'no_result'" type="warning">
                          Aucun utilisateur trouvé, veuillez vérifier les données séléctionnées !
                          <v-spacer/>
                        </v-alert>

                        <v-btn :loading="searchLoading"
                               block
                               color="gifty"
                               dark
                               depressed large
                               @click="searchUsers">
                          <v-icon>mdi-magnify</v-icon>
                          Recherche
                        </v-btn>

                      </v-card-text>


                      <v-card-text v-else class="pa-0 mt-5">

                        <v-divider/>

                        <v-card-title>

                          <v-spacer/>

                          <v-btn class="rounded-lg"
                                 color="grey"
                                 text @click="onFilter = true">
                            <v-icon left>mdi-arrow-left</v-icon>
                            Modifier le filtrage
                            <v-icon right>mdi-filter-outline</v-icon>
                          </v-btn>

                        </v-card-title>
                        <v-divider/>
                        <div class="d-flex justify-space-between align-center">

                          <v-switch v-model="checked_all"
                                    class="ml-3"
                                    color="success"
                                    dense
                                    label="Sélectionner tous les utilisateurs trouvés !"></v-switch>
                          <v-spacer/>

                          <div class="mr-3">
                            <span class="gifty--text">Total : {{ total }}</span>
                            <span class="mx-5"></span>
                            <span class="primary--text">
                                             Total sélectionné :
                                             {{ checked_all ? total : selected_ids.length }}
                                          </span>
                          </div>

                        </div>

                        <v-divider/>

                        <v-alert v-if="errors && errors.selected_ids"
                                 border="left"
                                 class="mt-3"
                                 dismissible
                                 text
                                 type="error">
                          <strong> Erreur : </strong> Veuillez sélectionner des utilisateurs
                        </v-alert>

                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                            <tr>
                              <th>
                                <span v-if="checked_all">#</span>
                                <v-icon v-else
                                        class="pa-0 mt-0"
                                        color="success"
                                        hide-details
                                        @click="toggleAll"
                                        @mousedown.prevent>
                                  {{ icon }}
                                </v-icon>
                              </th>
                              <th>Nom</th>
                              <th>Email</th>
                              <th>N°Téléphone</th>
                              <th>Catégorie</th>
                              <th>Wilaya</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(user,index) in users"
                                :key="index"
                            >
                              <td>
                                <v-icon v-if="checked_all" color="success">mdi-check-circle</v-icon>

                                <v-checkbox v-else
                                            v-model="selected_ids"
                                            :value="user.account.id"
                                            class="pa-0 ma-0"
                                            color="success"
                                            hide-details
                                            multiple
                                            success></v-checkbox>

                              </td>

                              <td class="text-no-wrap">
                                <v-avatar size="35">
                                  <v-img
                                      :src="user.account && user.account.photo ? getImageLink(user.account.photo) : require('@/assets/avatar.png')"
                                      class="grey lighten-4"></v-img>
                                </v-avatar>

                                {{ user.name }}

                              </td>

                              <td class="font-weight-medium">
                                {{ user.email }}
                              </td>

                              <td class="text-no-wrap">
                                <v-icon :style="{marginTop:'-3px'}"
                                        color="gifty"
                                        small>mdi-phone-outline
                                </v-icon>
                                {{ user.phone ? user.phone : "N'a pas de N°.." }}
                              </td>

                              <td>
                                {{ user.category ? user.category.name : '....' }}
                              </td>

                              <td>
                                {{ user.wilaya ? user.wilaya.name : '....' }}
                              </td>

                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>

                        <v-divider v-if="total > 10"/>

                        <v-card-actions v-if="total > 10">

                          <div :style="{width: '120px'}">
                            <v-select v-model="perPage"
                                      :items="[10,20,30,50,100]"
                                      dense
                                      hide-details
                                      outlined></v-select>
                          </div>

                          <v-spacer/>

                          <v-pagination v-model="pagination.current"
                                        :length="pagination.total"
                                        circle
                                        total-visible="8"
                                        @input="searchUsers"></v-pagination>

                        </v-card-actions>

                      </v-card-text>


                      <v-card-actions>
                        <v-spacer/>

                        <v-btn class="mr-2"
                               color="primary"
                               text
                               @click="tab = 0">
                          <v-icon left>mdi-arrow-left</v-icon>
                          Retour
                        </v-btn>

                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="!users.length || (!checked_all && !selected_ids.length)"
                                   :loading="btnLoading"
                                   color="primary"
                                   depressed
                                   v-bind="attrs"
                                   v-on="on"
                            >
                              <v-icon left>mdi-content-save</v-icon>
                              Enregistrer
                            </v-btn>
                          </template>
                          <v-list>

                            <v-list-item @click="save('new')">
                              <v-list-item-icon>
                                <v-icon color="success">mdi-check-circle</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Enregistrer et nouveau
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="save('back')">
                              <v-list-item-icon>
                                <v-icon color="success">mdi-check-circle</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Enregistrer et retour
                              </v-list-item-title>
                            </v-list-item>

                          </v-list>
                        </v-menu>
                      </v-card-actions>

                    </v-card>
                  </v-tab-item>

                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>


    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import axios from "axios"
import moment from "moment";

export default {
  data() {
    return {
      dateNow: moment().format('YYYY-MM-DD'),
      menuStartDate: false,
      menuEndDate: false,

      id: this.$route.params.id,
      tab: 0,
      btnLoading: false,
      assignmentBtnLoading: false,
      searchLoading: false,
      showLoading: false,

      serviceLoading: false,
      services: [],

      form: {
        name: null,
        start_date: null,
        end_date: null,
        assignment_type: "all",
        is_active: true,
        services: [{
          type: 'percentage'
        }],
        users_assignment_type: 'specify',
        payment_methods: [],
        conversion_methods: [],
      },
      errors: {},

      /**Filter**/
      onFilter: true,
      filter: {
        country_ids: [],
        wilaya_ids: [],
        category_ids: [],
        database_ids: [],
        role_ids: [],
        versions: [],
        keyword: '',
      },

      countries: [],
      wilayas: [],
      categories: [],
      databases: [],
      versions: [],
      roles: [],

      /**Search results **/
      users: [],
      searchResult: null,

      /** Table users ***/
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      checked_all: true,
      selected_ids: [],

    };
  },
  methods: {
    save(type) {
      this.errors = {}

      let url = '/v1/cashbacks/create-or-update'

      if (type === 'befor_assignment') {
        this.assignmentBtnLoading = true
        url = '/v1/cashbacks/befor-assignment/validation'
      } else {
        this.btnLoading = true
      }

      let data = this.form
      data['type'] = type
      data['checked_all'] = this.checked_all
      data['selected_ids'] = this.selected_ids
      data['tab'] = this.tab
      data['filter'] = this.filter
      data['id'] = this.id
      HTTP.post(url, data).then(() => {

        this.assignmentBtnLoading = false
        this.btnLoading = false

        let successMessage = "Cet cashback a été enregistré avec succès."

        if (type === 'back') {
          this.$successMessage = successMessage
          this.$router.push('/cashback')
        }

        if (type === 'new') {
          this.$successMessage = successMessage
          this.$router.push('/cashback/create').catch(() => {
          });
          this.resetForm()
        }
        if (type === 'befor_assignment') {
          this.tab = 1
        }

      }).catch(err => {
        this.btnLoading = false
        this.assignmentBtnLoading = false

        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        console.log(err)
      })
    },
    getServices() {
      this.serviceLoading = true
      HTTP.get('/v1/cashbacks/services').then(res => {
        this.serviceLoading = false
        this.services = res.data.data
      }).catch(err => {
        this.serviceLoading = false
        console.log(err)
      })
    },
    searchUsers() {
      this.searchLoading = true
      this.searchResult = null
      this.onFilter = true

      HTTP.get('/v1/cashbacks/search/users?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          filter: this.filter,
        }
      }).then(res => {
        this.searchLoading = false
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
        this.users = res.data.data.data

        this.selected_ids = JSON.parse(JSON.stringify(this.selected_ids))

        this.checked_all = this.selected_ids.length === this.total;

        if (this.users.length > 0) {
          this.searchResult = 'ok_result'
          this.onFilter = false
        } else {
          this.searchResult = 'no_result'
        }

      }).catch(err => {
        this.serviceLoading = false
        console.log(err)
      })
    },
    resetForm() {
      this.tab = 0
      this.form = {
        name: null,
        start_date: null,
        end_date: null,
        assignment_type: "all",
        is_active: true,
        services: [{}],
        users_assignment_type: 'specify',
        payment_methods: [],
        conversion_methods: [],
      }
      this.filter = {
        country_ids: [],
        wilaya_ids: [],
        category_ids: [],
        database_ids: [],
        role_ids: [],
        versions: [],
        keyword: '',
      }
      this.errors = {}
      this.users = []
      this.searchResult = null
      this.checked_all = true
      this.selected_ids = []
    },
    async getFilteData() {
      axios.all([
        await HTTP.get('/v1/users/countries'),
        await HTTP.get('/v1/users/wilayas'),
        await HTTP.get('/v1/users/categories'),
        await HTTP.get('/v1/users/databases'),
        await HTTP.get('/v1/users/versions'),
        await HTTP.get('/v1/users/roles'),
      ]).then(axios.spread((obj1, obj2, obj3, obj4, obj5, obj6) => {
        this.countries = obj1.data.data
        this.wilayas = obj2.data.data
        this.categories = obj3.data.data
        this.databases = obj4.data.data
        this.versions = obj5.data.data
        this.roles = obj6.data.data
      })).catch(err => {
        console.log(err)
      })
    },
    toggleAll() {
      if (this.selected_ids.length === this.users.length) {
        this.selected_ids = []
      } else {
        this.selected_ids = this.users.map(el => el.account.id)
      }
    },
    getCashbackById() {
      this.showLoading = true
      HTTP.get('/v1/cashbacks/' + this.id + '/show').then(res => {
        this.showLoading = false

        let cashback = res.data.data

        if (cashback) {
          this.form = {
            name: cashback.name,
            start_date: cashback.start_date,
            end_date: cashback.end_date,
            assignment_type: cashback.assignment_type,
            is_active: cashback.is_active,
            services: cashback.services.map((el) => ({
              'service_id': el.id,
              'min_amount': el.pivot.min_amount,
              'max_amount': el.pivot.max_amount,
              'type': el.pivot.type,
              'value': el.pivot.value,
            })),
            users_assignment_type: cashback.users_assignment_type,
            payment_methods: cashback.payment_methods,
            conversion_methods: cashback.conversion_methods
          }

          this.filter = cashback.users_filter

          if (cashback.account_ids && cashback.account_ids.length) {
            this.selected_ids = cashback.account_ids
          }

          if (this.selected_ids.length > 0) {
            this.onFilter = false
            this.searchUsers()
          } else {
            this.onFilter = true
          }

        } else {
          this.$router.push('/404')
        }
      }).catch(err => {
        this.showLoading = false
        console.log(err)
      })
    },

    checkDisabled() {
      if (this.form.assignment_type === "all" && this.form.users_assignment_type === "specify") {
        return true
      }
    }

  },
  created() {
    this.getServices()
    this.getFilteData()
    if (this.id) {
      this.getCashbackById()
    }
  },
  watch: {
    perPage() {
      this.searchUsers();
    }
  },
  computed: {
    icon() {
      if (this.selected_ids.length === this.users.length) return 'mdi-close-box'
      if (this.selected_ids.length > 0 && this.selected_ids.length !== this.users.length) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  }
};
</script>

<style>


</style>
